export const icons = {
    true_false: 'mdi-plus-minus-variant',
    single_answer: 'mdi-radiobox-marked',
    multiple_answers: 'mdi-checkbox-multiple-marked-outline',
    free_answer: 'mdi-text-box-outline',
    fill_text: 'mdi-contain'
}

export const types = [
    'true_false',
    'single_answer',
    'multiple_answers',
    'free_answer',
    'fill_text'
]

export const statuses = {
    BROKEN: 'broken',
    PENDING: 'pending',
    PASSED: 'passed',
    NOT_PASSED: 'not_passed',
    TRY_AGAIN: 'try_again',
}
