<template>
    <questions-process-start
        v-if="!isProcess && !isComplete"
        :loading="loading"
        :item="item"
        :scrollable="scrollable"
        :height="height"
        @start="onStart"
        @close="$emit('close', result)"
    />
    <questions-process-step
        v-else-if="isProcess"
        :key="`current-step-${currentStep}`"
        :item="currentSet"
        :scrollable="scrollable"
        :height="height"
        :progress="questionsProcess"
        :skippable="isSkippable"
        :last="isLastSet"
        :complete="isComplete"
        :timer="result.duration ? timer : null"
        :current-set-count="currentSetCount"
        :sets-count="setsCount"
        @reply="onReply"
        @skip="onSkip"
    />
    <questions-process-result
        v-else-if="showResults"
        :loading="loading"
        :title="title"
        :result="result"
        :scrollable="scrollable"
        :height="height"
        @close="onCloseResult"
    />
</template>

<script>
import _ from 'lodash'
import {
    createQuestionsResults,
    getQuestionsSets,
    updateQuestionsResults
} from '@apps/questions/api'
import { resultExample, timerProcess } from './utils'
import questionsProcessBaseMixin from '@apps/questions/components/QuestionsProcess/mixins/questionsProcessBaseMixin'
import { statuses } from '@apps/questions/utils'
import { memberFullName } from '@/components/Member/utils'

export default {
    name: 'QuestionsProcess',
    mixins: [questionsProcessBaseMixin],
    components: {
        QuestionsProcessStart: () => import('./QuestionsProcessStart'),
        QuestionsProcessResult: () => import('./QuestionsProcessResult'),
        QuestionsProcessStep: () => import('./QuestionsProcessStep'),
    },
    props: {
        uuid: {
            type: String,
            required: true
        },
        passedType: {
            type: String,
            default: null
        },
        passedUuid: {
            type: String,
            default: null
        },
        sharedType: {
            type: String,
            default: null
        },
        sharedUuid: {
            type: String,
            default: null
        },
    },
    computed: {
        resultExample() {
            return resultExample()
        },
        title() {
            if(!this.item) return null

            return this.item.title
        },
        currentSet() {
            return this.sets[this.currentSetIndex]
        },
        isLastSet() {
            return this.passedSetsCount + 1 === this.setsCount
        },
        isSkippable() {
            return this.item.options.skip_question
        },
        sets() {
            return this.shuffleSets
        },
        setsCount() {
            return this.sets.length
        },
        passedSetsCount() {
            return this.passedSets.length
        },
        questionsProcess() {
            return (100 / this.setsCount) * this.passedSetsCount
        },
        isSkippedSets() {
            return this.currentStep >= this.setsCount && this.skippedSets.length > 0
        },
        isTestMode() {
            return !!this.passedType &&
                !!this.passedUuid &&
                !!this.sharedType &&
                !!this.sharedUuid
        },
        testModeData() {
            return {
                questions_uuid: this.item.uuid,
                passed_type: this.passedType,
                passed_uuid: this.passedUuid,
                shared_type: this.sharedType,
                shared_uuid: this.sharedUuid
            }
        },
        isProcess() {
            return !this.isComplete && this.started
        },
        isTimeout() {
            return this.timer.timeout
        },
        isComplete() {
            return (this.started && this.setsCount === this.passedSetsCount) ||
                this.isTimeout
        },
        showResults() {
            return this.isComplete
        }
    },
    data() {
        return {
            started: false,
            loading: true,
            item: null,
            shuffleSets: [],
            currentSetIndex: 0,
            currentStep: 1,
            currentSetCount: 1,
            passedSets: [],
            skippedSets: [],
            timer: {
                minutes: 1,
                quarter: 1,
                current: 0,
                timeout: false,
                timeDisplay: '00:00'
            },
            resultsUuid: null,
            result: {
                duration: null,
                spentOfTime: {
                    minutes: 0,
                    seconds: 0
                },
                authorName: null,
                begin: null,
                end: null,
                correctReplies: 0,
                incorrectReplies: 0,
                withoutReply: 0,
                scores: 0,
                totalScores: 0,
                totalSets: 0,
                percentage: 0,
                replies: [],
                notReplied: [],
                activity: []
            }
        }
    },
    watch: {
        isTimeout(flag) {
            if(flag) {
                this.saveResult()
            }
        }
    },
    mounted() {
        this.fetchItem()
    },
    methods: {
        async fetchItem() {
            this.loading = true

            try {
                const response = await getQuestionsSets(this.uuid, {
                    with: 'sets.media.document;author.member'
                })
                this.item = response.data.data

                this.result.duration = this.item.options ? this.item.options.duration : null
                this.result.authorName = memberFullName(this.item.author.member)
                this.result.totalSets = this.item.sets.length

                this.item.sets.forEach(o => {
                    this.result.totalScores += Number(o.score)
                })

                this.shuffleSets = _.shuffle(this.item.sets)

                this.$emit('update:item', this.item)
            } finally {
                this.loading = false
            }
        },
        async onStart() {
            this.loading = true
            this.result.begin = Date.now()

            try {
                // Create empty test results
                if(this.isTestMode) {
                    const data = {
                        ...this.testModeData,
                        title: this.title,
                        author_uuid: this.item.created_by,
                        status: statuses.BROKEN,
                        data: this.result
                    }

                    this.$notification.disabled()
                    const response = await createQuestionsResults(data)

                    this.resultsUuid = response.data.data.uuid
                }

                this.started = true

                if(this.result.duration) {
                    this.timer.minutes = this.result.duration
                    timerProcess(this.timer)
                }

                this.$emit('start', this.result)
            } catch (error) {
                this.$notification.make(
                    this.$trans('hint.system.serverError'),
                    {
                        type: this.$notification.TYPE.ERROR
                    }
                ).open()
            } finally {
                this.loading = false
            }
        },
        _pushActivity(uuid, title, skipped) {
            this.result.activity.push({
                uuid,
                title,
                skipped,
                timestamp: Date.now()
            })
        },
        async saveResult() {
            try {
                this.loading = true
                const result = this.result

                result.percentage = result.totalSets === 0 ? 0
                    : Math.round((100 / result.totalSets) * result.correctReplies)

                this.shuffleSets.forEach(o => {
                    if(!this.passedSets.includes(o.uuid)) {
                        result.notReplied.push({
                            notReplied: true,
                            uuid: o.uuid,
                            title: o.title,
                            type: o.type,
                            description: o.description,
                            score: o.score,
                            options: o.options,
                        })

                        result.withoutReply += 1
                    }
                })

                result.end = Date.now()

                const begin = result.begin
                const end = result.end
                const time = Math.round((end - begin) / 1000)

                result.spentOfTime.seconds = time % 60
                result.spentOfTime.minutes = (time - time % 60) / 60

                if(this.isTestMode) {
                    this.$notification.disabled()
                    await updateQuestionsResults(this.resultsUuid, {
                        title: this.title,
                        data: result,
                        status: statuses.PENDING
                    })
                } else {
                    setTimeout(() => {
                        this.loading = false
                    }, 1000)
                }
            } catch (error) {
                this.$notification.make(
                    this.$trans('hint.system.serverError'),
                    {
                        type: this.$notification.TYPE.ERROR
                    }
                ).open()
            } finally {
                this.loading = false
            }
        },
        onCloseResult() {
            this.$emit('close', this.result)
            this.$emit('finish', this.result)
        },
        onReply(reply) {
            this.passedSets.push(reply.uuid)

            this.result.replies.push(reply)
            this._pushActivity(reply.uuid, reply.title, false)

            if(reply.passed) {
                this.result.correctReplies++
                this.result.scores += Number(reply.score)
            } else {
                this.result.incorrectReplies++
            }

            if(!this.isComplete) {
                this.currentSetCount++
            }

            if(this.isSkippedSets) {
                this.currentSetIndex = this.skippedSets.shift()
            } else if(!this.isComplete) {
                this.currentSetIndex++
            }

            if(this.isComplete) {
                this.saveResult()
            } else {
                this.currentStep++
            }
        },
        onSkip(reply) {
            this._pushActivity(reply.uuid, reply.title, true)

            if(!this.skippedSets.includes(this.currentSetIndex)) {
                this.skippedSets.push(this.currentSetIndex)
            }

            if(!this.isComplete && !this.isSkippedSets) {
                this.currentSetIndex++
            } else if(this.isSkippedSets) {
                this.currentSetIndex = this.skippedSets.shift()
            }

            this.currentStep++
        },
        onCancel() {
            this.$emit('close')
        }
    }
}
</script>
