export default {
    props: {
        height: {
            type: String,
            default: '300px'
        },
        scrollable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        vCardTextBindings() {
            return {
                style: {
                    height: this.scrollable ? this.height : 'auto'
                }
            }
        }
    }
}
