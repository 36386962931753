/* eslint-disable */
export const timerProcess = (timer) => {
    if(!timer || !timer.minutes) return

    if(!timer.current) timer.current = 0

    timer.quarter = 1
    timer.timeout = false
    timer.time = timer.minutes * 60

    const quarterStep = timer.time / 4

    timer.timeDisplay = `${ timer.minutes.toString().padStart(2, '0') }:00`

    const go = () => {

        const min = timer.minutes.toString().padStart(2, '0')
        const sec = ((timer.time - timer.current) % 60).toString().padStart(2, '0')

        if(timer.current > 0 && timer.current < timer.time) {
            timer.timeDisplay = `${ min }:${ sec }`
        }

        if((timer.time - timer.current) % 60 === 0) {
            timer.minutes--
        }

        if(timer.current >= quarterStep) timer.quarter = 2
        if(timer.current >= quarterStep * 2) timer.quarter = 3
        if(timer.current >= quarterStep * 3) timer.quarter = 4

        if(timer.current < timer.time) {
            setTimeout(go, 1000)
            timer.current++
        } else {
            timer.timeDisplay = '00:00'
            timer.timeout = true
        }
    }

    go()
}

export const resultExample = () => {
    return {
        'title': 'Що таке Lorem Ipsum?',
        'description': null,
        'duration': null,
        'begin': 1656181750941,
        'end': 1656181783692,
        'correctReplies': 5,
        'incorrectReplies': 3,
        'scores': 14,
        'totalScores': 23,
        'totalSets': 8,
        'replies': [ {
            'title': '1 Lorem Ipsum - це текст-"риба", що використовується в друкарстві та дизайні.',
            'description': null,
            'type': 'fill_text',
            'options': {
                'text': [ 'Lorem Ipsum є, фактично, стандартною "', '__input__0', '" аж з XVI сторіччя, коли невідомий друкар взяв шрифтову гранку та склав на ній підбірку зразків', '__input__1', null ],
                'correct': {
                    '__input__0': 'рибою',
                    '__input__1': 'шрифтів'
                },
                'pattern': 'Lorem Ipsum є, фактично, стандартною "[рибою]" аж з XVI сторіччя, коли невідомий друкар взяв шрифтову гранку та склав на ній підбірку зразків [шрифтів]'
            },
            'score': 1,
            'reply': {
                '__input__0': 'ggg',
                '__input__1': 'ggg'
            },
            'passed': false
        }, {
            'title': '2 Звідки він походить?',
            'description': '<p>На відміну від поширеної думки <strong>Lorem</strong> Ipsum не є випадковим набором літер.&nbsp;</p>',
            'type': 'multiple_answers',
            'options': {
                'correct': [ '832', '260' ],
                'variants': [ {
                    'id': '832',
                    'value': 'CC',
                    'weight': '0'
                }, {
                    'id': '813',
                    'value': 'AA',
                    'weight': '1'
                }, {
                    'id': '260',
                    'value': 'BB',
                    'weight': '2'
                } ]
            },
            'score': 0,
            'reply': [ '813' ],
            'passed': false
        }, {
            'title': '5 Де собі взяти трохи?',
            'description': null,
            'type': 'free_answer',
            'options': { 'correct': 'free_answer' },
            'score': 1,
            'reply': 'ghgh ghgh',
            'passed': true
        }, {
            'title': '6 На відміну від поширеної думки Lorem Ipsum не є випадковим набором літер.',
            'description': null,
            'type': 'true_false',
            'options': { 'correct': 'false' },
            'score': 1,
            'reply': 'false',
            'passed': true
        }, {
            'title': '7 Існує багато варіацій уривків з Lorem Ipsum',
            'description': '<p>Якщо ви збираєтесь використовувати Lorem Ipsum, ви маєте упевнитись в тому, що всередині тексту не приховано нічого, що могло б викликати у читача конфуз</p>',
            'type': 'free_answer',
            'options': { 'correct': 'free_answer' },
            'score': 1,
            'reply': 'ghgh ghgh',
            'passed': true
        }, {
            'title': '8 Quest',
            'description': null,
            'type': 'free_answer',
            'options': { 'correct': 'free_answer' },
            'score': 1,
            'reply': 'ghgh ghgh',
            'passed': true
        }, {
            'title': '4 Що таке Lorem Ipsum?',
            'description': '<p><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;- це текст-"риба", що використовується в друкарстві та дизайні.&nbsp;</span></p>',
            'type': 'true_false',
            'options': { 'correct': 'true' },
            'score': 10,
            'reply': 'true',
            'passed': true
        }, {
            'title': '3 Чому ми ним користуємось?',
            'description': '<p><span style="color: rgb(0, 0, 0);">Вже давно відомо, що читабельний зміст буде заважати зосередитись людині, яка оцінює композицію сторінки.&nbsp;</span></p>',
            'type': 'single_answer',
            'options': {
                'correct': [ '522' ],
                'variants': [ {
                    'id': '522',
                    'value': 'Варіант відповіді 2',
                    'weight': '0'
                }, {
                    'id': '377',
                    'value': 'Варіант відповіді 1',
                    'weight': 1
                } ]
            },
            'score': 0,
            'reply': [ '377' ],
            'passed': false
        } ],
        'activity': [ {
            'title': '1 Lorem Ipsum - це текст-"риба", що використовується в друкарстві та дизайні.',
            'skipped': false,
            'timestamp': 1656181750941
        }, {
            'title': '2 Звідки він походить?',
            'skipped': false,
            'timestamp': 1656181753789
        }, {
            'title': '3 Чому ми ним користуємось?',
            'skipped': true,
            'timestamp': 1656181755693
        }, {
            'title': '4 Що таке Lorem Ipsum?',
            'skipped': true,
            'timestamp': 1656181757757
        }, {
            'title': '5 Де собі взяти трохи?',
            'skipped': false,
            'timestamp': 1656181761014
        }, {
            'title': '6 На відміну від поширеної думки Lorem Ipsum не є випадковим набором літер.',
            'skipped': false,
            'timestamp': 1656181763173
        }, {
            'title': '7 Існує багато варіацій уривків з Lorem Ipsum',
            'skipped': false,
            'timestamp': 1656181766005
        }, {
            'title': '8 Quest',
            'skipped': false,
            'timestamp': 1656181770380
        }, {
            'title': '3 Чому ми ним користуємось?',
            'skipped': true,
            'timestamp': 1656181773972
        }, {
            'title': '4 Що таке Lorem Ipsum?',
            'skipped': true,
            'timestamp': 1656181776989
        }, {
            'title': '3 Чому ми ним користуємось?',
            'skipped': true,
            'timestamp': 1656181778596
        }, {
            'title': '4 Що таке Lorem Ipsum?',
            'skipped': false,
            'timestamp': 1656181781444
        }, {
            'title': '3 Чому ми ним користуємось?',
            'skipped': false,
            'timestamp': 1656181783692
        } ]
    }
}
